import "./App.css";
import React from "react";
import SwipeableStatement from "../../Components/SwipeableCard";

const GRAMMAR = [
  "The apple is red",
  "It is John's apple",
  "I give John the apple",
  "We give him the apple",
  "He gives it to John",
  "She gives it to him",
  "Is the apple red?",
  "The apples are red",
  "I must give it to him",
  "I want to give it to her",
  "I'm going to know tomorrow",
  "I can't eat the apple",
];

const KANNADA = [
  "Seebu Kempu ide",
  "Seebu John ge ide",
  "Naanu John ge Seebu koDuttene",
  "Naavu avanige Seebu koDuttivi",
  "Avanu John ge koDuttane",
  "Avalu avanige koDuttale",
  "Seebu Kempu ideya?",
  "SeebugaLu Kempuvaagive",
  "Naanu avanige koDuttakkoLa beku",
  "Naanu avalige koDuttakkoLa beku",
  "Naale nanage gotthu",
  "Naanu Seebu tinakka beku",
];

const GERMAN = [
  "Der Apfel ist rot",
  "Es ist Johns Apfel",
  "Ich gebe John den Apfel",
  "Wir geben ihm den Apfel",
  "Er gibt ihn John",
  "Sie gibt ihn ihm",
  "Ist der Apfel rot?",
  "Die Äpfel sind rot",
  "Ich muss ihn ihm geben",
  "Ich will ihn ihr geben",
  "Ich werde es morgen wissen",
  "Ich kann den Apfel nicht essen",
];

export function KannadaDeck() {
  return (
    <div className="App">
      <SwipeableStatement statements={GRAMMAR} translations={KANNADA} />
    </div>
  );
}
export function GermanDeck() {
  return (
    <div className="App">
      <SwipeableStatement statements={GRAMMAR} translations={GERMAN} />
    </div>
  );
}
