import React, { useState } from "react";
import { useSwipeable } from "react-swipeable";
import { useSpring, animated } from "react-spring";
import "./style.css";

const SwipeableStatement = ({
  statements, // Assume an array of { statement, translation }
  translations,
}) => {
  const [index, setIndex] = useState(0);
  const flickThreshold = 0.5; // Adjust based on your needs
  const cardHeight = 600;

  const animationConfig = {
    mass: 1.5,
    tension: 210,
    friction: 20,
    duration: 300,
  };

  const [props, setProps] = useSpring(() => ({
    from: { opacity: 1, transform: "translateY(0vh)" },
    config: animationConfig,
  }));

  const swipeHandlers = useSwipeable({
    onSwiped: (eventData) => {
      let swipeDirection = eventData.dir;
      let velocityThresholdMet = eventData.velocity > flickThreshold;

      if (swipeDirection === "Up" && velocityThresholdMet) {
        setProps({
          opacity: 0,
          transform: `translateY(-${cardHeight}px)`,
          config: animationConfig,
        });
      } else if (swipeDirection === "Down" && velocityThresholdMet) {
        setProps({
          opacity: 0,
          transform: `translateY(${cardHeight}px)`,
          config: animationConfig,
        });
      }

      if (velocityThresholdMet) {
        setTimeout(
          () => {
            setNextCard(swipeDirection);
          },
          index !== 0 ? 200 : 0 // No Delay for the first card since nothing is disappearing
        );
      }
    },
    trackMouse: true,
  });

  const setNextCard = (swipeDirection) => {
    const nextIndex = (index + 1) % statements.length;
    setIndex(nextIndex);

    const fromPosition =
      swipeDirection === "Up" ? `${cardHeight}px` : `-${cardHeight}px`;
    setProps({
      from: { opacity: 0, transform: `translateY(${fromPosition})` }, // Adjusting based on card height
      to: { opacity: 1, transform: "translateY(0)" },
      reset: true,
      config: animationConfig,
    });
  };

  const statement = statements[index];
  const translation = translations[index];

  return (
    <animated.div
      {...swipeHandlers}
      className="swipeable-statement"
      style={props}
    >
      <div className="statement-content">
        <p className="statement-text">{statement}</p>
        <p className="statement-text">{translation}</p>
      </div>
    </animated.div>
  );
};

export default SwipeableStatement;
