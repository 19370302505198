import React from "react";
import "./About.css";
const About = () => {
  return (
    <div class="aboutus">
      <h1>About PolyLingo</h1>
      <p>
        PolyLingo is a flashcard app designed to teach new languages. Our
        approach is based on Tim Ferris's minimal learning approach.
      </p>
      <p>
        We believe in the power of learning the most commonly used words and
        phrases in a language to quickly gain proficiency and understanding. Our
        flashcards focus on these key elements to maximize your learning
        efficiency.
      </p>
    </div>
  );
};

export default About;
