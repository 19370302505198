import React from "react";
import { Link } from "react-router-dom";
import "./Home.css";

const Home = () => {
  const gridPages = [
    {
      path: "/kannada",
      title: "Learn Kannada",
    },
    {
      path: "/german",
      title: "Learn German",
    },
  ];

  return (
    <div className="home">
      <Link to="/about">About</Link>
      <div className="card-grid">
        {gridPages.map((page) => (
          <Link key={page.path} to={page.path} className="card link">
            <div className="card-content">
              <h2>{page.title}</h2>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Home;
